<template>
    <div
        class=" secondary-bg text-white rounded-lg shadow-md relative hover:shadow-lg hover:scale-[1.02] hover:cursor-finger transition-all duration-300"
    >
        <div class="absolute top-4 left-2 h-[calc(80%-50px)] w-1 bg-purple-500 rounded-lg"></div>
        <div class="flex flex-col h-full">
            <div class="flex-1 py-4 px-8">
                <div class="flex flex-wrap gap-2 mb-3">
                    <span v-for="(tag, index) in tags.split(',')" :key="index"
                        class="bg-gray-600 text-xs px-3 py-1 rounded-[3px] text-gray-300">
                        {{ tag }}
                    </span>
                </div>

                <div>
                    <h2 class="text-lg font-semibold mb-2">
                        {{ title }}
                    </h2>
                    <p class="text-gray-400 text-sm mb-4">
                        {{ subtitle }}
                    </p>
                </div>
            </div>
            <div class="flex justify-between items-center px-4 py-3 secondary-bg rounded-b-lg border-t border-white">
                <div class="flex items-center text-sm text-gray-400">
                </div>
                <button 
                    class="flex items-end text-sm text-gray-400 hover:text-gray-200" 
                    @click="share">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" class="w-4 h-5 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M8 7V3m8 4V3m-6 8h6m-6 4h6M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    {{ convertedDate }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tags: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
    },
    methods: {
        share() {
            alert("Share functionality coming soon!");
        },
    },
    computed: {
        convertedDate() {
            const date = new Date(this.date);
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true, // AM/PM format
                timeZone: 'Asia/Kolkata',
            };

            const formatted = date.toLocaleString('en-IN', options);
            
            // Adjust the format to `dd/mm/yy H:i:s AM/PM` by replacing comma
            const [datePart, timePart] = formatted.split(', ');
            const [time, amPm] = timePart.split(' ');

            return `${datePart} ${time} ${amPm.toUpperCase()}`;
        }
    }
};
</script>

<style scoped>
/* Add any additional scoped styling if needed */
</style>
