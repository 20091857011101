export default {
  namespaced: true,
  state: {
    topics: {
      result: {} // Use result here, if necessary
    },
    project_upload_id: null
  },
  mutations: {
    // Set the entire topics object
    SET_TOPICS(state, topics) {
      state.topics.result = topics.result || topics; // Assign directly to result
    },
    SET_PROJECT_UPLOAD_ID(state, topics) {
      state.project_upload_id = topics.project_upload_id; // Assign directly to result
    },
    // Delete a topic and its subtopics
    DELETE_TOPIC(state, topic) {
      if (state.topics.result[topic]) {
        delete state.topics.result[topic]; // Delete the topic from the state
      }
    },
    // Delete a specific subtopic
    DELETE_SUBTOPIC(state, { topic, subIndex }) {
      if (state.topics.result[topic]) {
        state.topics.result[topic].splice(subIndex, 1); // Remove the subtopic
        if (state.topics.result[topic].length === 0) {
          delete state.topics.result[topic]; // Delete topic if no subtopics remain
        }
      }
    },
    UPDATE_TOPIC(state, { oldKey, newKey, subtopics }) {
      // Add new key and remove old key
      const newResult = { ...state.topics.result };
      newResult[newKey] = subtopics;
      delete newResult[oldKey];
      state.topics.result = newResult;
    },
    UPDATE_SUBTOPIC(state, { topicKey, subIndex, subtopic }) {
      state.topics.result[topicKey][subIndex] = subtopic; // Update the subtopic at the given index
    }
  },
  actions: {
    setGeneratedTopics({ commit }, topics) {
      commit("SET_TOPICS", topics);
    },
    setProjectUploadId({ commit }, topics) {
      commit("SET_PROJECT_UPLOAD_ID", topics);
    },
    deleteTopic({ commit }, topic) {
      commit("DELETE_TOPIC", topic);
    },
    deleteSubTopic({ commit }, { topic, subIndex }) {
      commit("DELETE_SUBTOPIC", { topic, subIndex });
    },
    updateTopic({ commit }, payload) {
      commit("UPDATE_TOPIC", payload);
    },
    updateSubTopic({ commit }, payload) {
      commit("UPDATE_SUBTOPIC", payload);
    }
  },
  getters: {
    // Getter for the topics state
    topics: (state) => state.topics.result,
  },
};
