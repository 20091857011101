<template>
  <div class="flex h-screen">
    <!-- Left Part -->
    <div class="flex flex-col justify-between w-1/2 bg-black p-8">
      <!-- Center Content -->
      <img :src="logo" alt="Company Logo" class="w-48 h-auto mb-8 self-start" />
      <div class="flex flex-col justify-center items-center flex-grow">
        <h1 class="text-4xl font-bold text-white mb-4">Sign In</h1>
        <p class="text-lg text-white mb-12">
          Welcome! Please Sign in to your account
        </p>
        <button @click="login"
          class="px-32 py-3 text-white font-medium rounded-lg bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 hover:from-purple-800 hover:to-purple-400 transition-all duration-300">
          Login with Kearney SSO
        </button>
      </div>
      <!-- Footer Paragraph -->
      <p class="text-white text-sm text-center text-justify leading-relaxed max-w-md mx-auto mb-12">
        By clicking on 'Sign In', you confirm to have read the 'Kearney Privacy
        Statement' and provide consent to use your personal information like
        Name and Email ID.
        <a href="#" class="underline text-purple-400 hover:text-purple-600">
          View Privacy Statement
        </a>
      </p>
    </div>
    <!-- Right Part -->
    <div class="relative w-1/2 min-h-screen flex items-center justify-center"
      :style="{ backgroundImage: `url(${imageSrc})`, backgroundSize: 'cover', backgroundPosition: 'center' }">
      <div class="p-20">
        <h1 class="text-[3rem] font-bold introducing text-white mb-4">Introducing CASPER</h1>
        <p class="text-[2rem] text-white mb-4">
          Cognitive Agent for Strategic Product Excellence and Renewal
        </p>
        <p class="text-[2rem] font-bold text-white product-development">
          - Product development <br>powered by PERLab AI agents
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { PublicClientApplication } from "@azure/msal-browser";
import loginImage from "@/assets/img/fe/login-bg.png";

export default {
  name: "Login",
  data() {
    return {
      imageSrc: loginImage,
      logo: require("@/assets/img/fe/kearney-logo-white.png"),
      msalInstance: null, // Placeholder for the MSAL instance
    };
  },
  created() {
    const msalConfig = {
      auth: {
        clientId: "df8b9d90-db9e-4d9c-8ac0-3ac257739842", // Replace with your Azure AD App Client ID
        authority: "https://login.microsoftonline.com/5dc645ed-297f-4dca-b0af-2339c71c5388", // Replace with your Tenant ID
        redirectUri: process.env.VUE_APP_BASE_URL, // Replace with your app's redirect URI
      },
    };

    this.msalInstance = new PublicClientApplication(msalConfig);
    this.msalInstance.initialize().then(() => {
      console.log("MSAL initialized successfully.");
    });
  },
  methods: {
    async login() {
      if (!this.msalInstance) {
        console.error("MSAL instance is not initialized.");
        return;
      }

      try {
        const loginResponse = await this.msalInstance.loginPopup({
          scopes: ["User.Read"], // Specify necessary scopes
        });
        console.log("Login Successful:", loginResponse);

        // Optionally, handle token retrieval
        const account = this.msalInstance.getAllAccounts()[0];
        const tokenResponse = await this.msalInstance.acquireTokenSilent({
          account,
          scopes: ["User.Read"],
        });

        const rawName = loginResponse.account.name; // e.g., "sahu, shubham"
        const [lastName, firstName] = rawName.split(", ");
        const formattedName = `${firstName.charAt(0).toUpperCase()}${firstName.slice(1)} ${lastName}`;

        // Store in local storage
        localStorage.setItem("loginData", JSON.stringify({
          name: formattedName,
          accessToken: tokenResponse.accessToken,
          username: account.username,
        }));

        console.log("Access Token:", tokenResponse.accessToken);
        this.$router.push("/projects");
      } catch (error) {
        console.error("Login Failed:", error);
      }
    },
  },
};
</script>

<style scoped>
/* No additional styles needed; Tailwind handles all the styling */
.introducing {
  font-weight: 900;
}
.product-development {
  font-weight: 800;
}
</style>
