<template>
  <div class="bg-black text-white">
    <div class="w-1/3 flex">
      <button
        class="rounded-tl-md rounded-tr-md flex-1 py-4 text-center transition mr-2 border border-gray-700 hover:secondary-bg"
        :class="{
          'border-b secondary-bg': activeTab === 'generate',
          'hover:border-gray-500': activeTab !== 'generate'
        }" @click="activeTab = 'generate'">
        Image Generation
      </button>
      <button
        class="flex-1 rounded-tl-md rounded-tr-md py-4 text-center transition border border-gray-700 hover:secondary-bg"
        :class="{
          'border-b-0 secondary-bg': activeTab === 'edit',
          'hover:border-gray-500': activeTab !== 'edit'
        }" @click="activeTab = 'edit'">
        Edit Image
      </button>
    </div>
    <div class="border border-gray-700">
      <div v-if="activeTab === 'generate'" class="px-8 pb-8 my-4 space-y-4 pt-8">
        <h1 class="text-2xl font-bold">Title of the Product</h1>
        <p class="text-gray-400">
          The Cordless Drill Kit is the ultimate solution for all your DIY projects and home improvement tasks.
          Powered by a robust 20V lithium-ion battery, this drill offers unmatched convenience, reliability, and
          power, allowing you to tackle everything from basic drilling to more advanced tasks with ease.
        </p>
        <form @submit.prevent="handleSubmitGenerateNew">
          <div class="flex items-center space-x-4 pt-6">
            <!-- Product Name Field (25% width) -->
            <div class="flex flex-col w-1/4">
              <label for="productName" class="text-gray-300 mb-2">{{ this.keyLabelName }}</label>
              <select id="productName" v-model="selectedProduct"
                class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
                <option v-for="key in keyList" :value="key" :key="key">{{ key }}</option>
              </select>
            </div>

            <!-- Prompt Field (50% width) -->
            <div class="flex flex-col w-1/2">
              <label for="prompt" class="text-gray-300 mb-2">Prompt</label>
              <input id="prompt" v-model="form.prompt" type="text"
                class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500" />
            </div>

            <!-- Select Model (25% width) -->
            <div class="flex flex-col w-1/4">
              <label for="model" class="text-gray-300 mb-2">Select Model</label>
              <select id="model" v-model="form.model"
                class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
                <option value="model1">Base Mode</option>
                <option value="model2">BAT Fine Tuned Model</option>
              </select>
            </div>
          </div>
          <button type="submit"
            class="mb-4 w-[20%] px-10 mt-4 py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white rounded-[3px] font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500">
            {{ loading ? "Loading..." : "Generate Image" }}
          </button>
          <img :src="imageSrc" v-if="imageSrc" alt="Recommendation Preview" class="w-1/2 rounded" />
          <button v-if="imageSrc" @click="downloadNewImage"
            class="mb-4 w-[20%] px-10 mt-4 py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white rounded-[3px] font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500">
            Download
          </button>
        </form>
      </div>

      <div v-else class="px-8 pb-8 my-4 space-y-4 pt-8">
        <h1 class="text-2xl font-bold">Title of the Product</h1>
        <p class="text-gray-400">
          The Cordless Drill Kit is the ultimate solution for all your DIY projects and home improvement tasks.
          Powered by a robust 20V lithium-ion battery, this drill offers unmatched convenience, reliability, and
          power, allowing you to tackle everything from basic drilling to more advanced tasks with ease.
        </p>

        <form @submit.prevent="editHandleSubmit" class="flex items-center space-x-4 pt-6">
          <!-- Prompt Field (50% width) -->
          <div class="flex flex-col w-1/2">
            <label for="recommendationChanges" class="text-gray-300 mb-2">Recommendation Changes</label>
            <input id="recommendationChanges" v-model="formEdit.recommendationChanges" type="text"
              class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500" />
          </div>
          <!-- Select Model (25% width) -->
          <div class="flex flex-col w-1/4">
            <label for="category" class="text-gray-300 mb-2">Category</label>
            <select id="category" v-model="formEdit.category"
              class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
              <option value="model1">Category 1</option>
              <option value="model2">Category 2</option>
              <option value="model3">Category 3</option>
            </select>
          </div>

          <!-- Select Model (25% width) -->
          <div class="flex flex-col w-1/4">
            <label for="model" class="text-gray-300 mb-2">Select Model</label>
            <select id="model" v-model="formEdit.model"
              class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
              <option selected value="model1">Base Mode</option>
              <option value="model2">BAT Fine Tuned Model</option>
            </select>
          </div>
        </form>
        <!-- <div class="mt-8 flex flex-col items-center w-full border border-gray-700 rounded-[3px] p-4"> -->
          <ImageEditor
            :recommendationChanges="formEdit.recommendationChanges"
            :category="formEdit.category"
            :model="formEdit.model"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import ImageEditor from "../ImageEditor.vue";

export default {
  props: {
  projectId: {
    type: [String, Number], // Adjust type according to your needs
    required: true, // projectId is mandatory
  },
  projectUploadId: {
    type: [String, Number], // Adjust type according to your needs
    required: false, // projectUploadId is optional
    default: null, // Default value when not provided
  },
},
  components: {
    ImageEditor
  },
  data() {
    return {
      activeTab: 'generate',
      form: {
        productName: '',
        prompt: '',
        model: 'model1',
        category: '',
        recommendationChanges: ''
      },
      uploadedImage: null, // Holds the base64 representation of the uploaded image
      keyLabelName: '',
      keyList: [],
      selectedProduct: '',  // To store the selected product
      loading: false,
      base64Image: '',
      formEdit: {
        recommendationChanges: '',
        category: 'category1', // Default value for category
        model: 'model1' // Default value for model
      }
    };
  },
  created() {
    this.keyLabelName = sessionStorage.getItem('selectedCategory')
  },
  mounted() {
    this.keyList = this.dashbaordData?.recommendation?.map(recommendation => recommendation.Key);
  },
  computed: {
    ...mapState("dashboard", ["dashbaordData"]),
    imageSrc() {
      return this.base64Image ? `data:image/jpeg;base64,${this.base64Image}` : null;
    },
  },
  methods: {
    downloadNewImage() {
      const link = document.createElement('a');
      link.href = this.imageSrc;
      link.download = 'recommended-design.jpeg'; // Set the filename
      link.click();
    },
    async handleSubmitGenerateNew() {
      console.log("Generating image with prompt:", this.form.prompt);

      const paylod = {
        prompt: this.form.prompt,
        height: 1024,
        width: 1024,
        negative_prompt: ''
      }
      try {
        this.loading = true;
        const response = await axios.post('https://kearneysofac-dev.azure-api.net/casper-be/generate', paylod);
        const responseData = response?.data || [];

        this.base64Image = responseData?.image;

        console.log(responseData, 'responseData')
      } catch (error) {
        this.loading = false;
        console.error(error);
        alert("Failed to generate topics.");
      } finally {
        this.loading = false;
      }
    },
    editHandleSubmit() {
      console.log("Generating image with prompt:", this.form.prompt);
    },
  },
  watch: {
    selectedProduct(newProduct) {
      console.log(newProduct, 'newProduct')
      console.log(this.dashbaordData?.recommendation)
      const selectedRecommendation = this.dashbaordData?.recommendation?.find(recommendation => recommendation.Key == newProduct)?.['Design Recommendations'];
      console.log(selectedRecommendation, 'selectedRecommendation')
      this.form.prompt = selectedRecommendation || '';  // Update the prompt field
    }
  },
};
</script>
<style scoped>
</style>