<template>
  <div>
    <canvas ref="barChart" v-show="isTopicChart"></canvas>
    <canvas ref="subtopicChart" v-show="!isTopicChart"></canvas>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { onMounted, ref } from "vue";

ChartJS.register(Title, Tooltip, Legend, BarElement, BarController, CategoryScale, LinearScale);

export default {
  props: {
    topicChartData: {
      type: Object,
      required: true,
    },
    subtopicChartData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const barChart = ref(null);
    const subtopicChart = ref(null);
    const isTopicChart = ref(true); // Determines which chart to show
    let topicChartInstance = null;
    let subtopicChartInstance = null;

    const sentimentColors = {
      negative: "rgba(255, 99, 132, 0.8)", // Red
      neutral: "rgba(255, 205, 86, 0.8)", // Yellow
      positive: "rgba(75, 192, 192, 0.8)", // Green
    };

    const calculatePercentages = (data, labels) => {
      return labels.map((label) => {
        const total =
          data[label].negative +
          data[label].neutral +
          data[label].positive;

        return {
          negative: (data[label].negative / total) * 100 || 0,
          neutral: (data[label].neutral / total) * 100 || 0,
          positive: (data[label].positive / total) * 100 || 0,
        };
      });
    };

    const initializeTopicChart = () => {
      const ctx = barChart.value.getContext("2d");
      const labels = Object.keys(props.topicChartData.topic_distribution);
      const topicData = props.topicChartData.topic_distribution;

      const percentages = calculatePercentages(topicData, labels);

      topicChartInstance = new ChartJS(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Negative",
              data: percentages.map((item) => item.negative),
              backgroundColor: sentimentColors.negative,
            },
            {
              label: "Neutral",
              data: percentages.map((item) => item.neutral),
              backgroundColor: sentimentColors.neutral,
            },
            {
              label: "Positive",
              data: percentages.map((item) => item.positive),
              backgroundColor: sentimentColors.positive,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
              ticks: {
                color: "white",
              },
            },
            y: {
              stacked: true,
              ticks: {
                beginAtZero: true,
                color: "white",
                callback: (value) => `${value}%`, // Show percentages
              },
            },
          },
          onClick: (event, elements) => {
            if (elements.length > 0) {
              const index = elements[0].index;
              const topic = labels[index];
              loadSubtopicChart(topic);
              isTopicChart.value = false;
            }
          },
        },
      });
    };

    const loadSubtopicChart = (topic) => {
      const ctx = subtopicChart.value.getContext("2d");
      const subtopics = props.subtopicChartData.subtopic_distribution[topic];
      if (!subtopics) return;

      const labels = Object.keys(subtopics);
      const percentages = calculatePercentages(subtopics, labels);

      if (subtopicChartInstance) {
        subtopicChartInstance.destroy();
      }

      subtopicChartInstance = new ChartJS(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Negative",
              data: percentages.map((item) => item.negative),
              backgroundColor: sentimentColors.negative,
            },
            {
              label: "Neutral",
              data: percentages.map((item) => item.neutral),
              backgroundColor: sentimentColors.neutral,
            },
            {
              label: "Positive",
              data: percentages.map((item) => item.positive),
              backgroundColor: sentimentColors.positive,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
              ticks: {
                color: "white",
              },
            },
            y: {
              stacked: true,
              ticks: {
                beginAtZero: true,
                color: "white",
                callback: (value) => `${value}%`, // Show percentages
              },
            },
          },
          onClick: () => {
            isTopicChart.value = true; // Return to topic chart
          },
        },
      });
    };

    onMounted(() => {
      initializeTopicChart();
    });

    return {
      barChart,
      subtopicChart,
      isTopicChart,
    };
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  max-height: 50vh;
  min-height: 30vh;
  margin-top: 20px;
}
</style>
