<template>

  <div class="flex items-center justify-between secondary-bg text-white py-4 px-16 fixed top-0 left-0 w-full z-50">
    <div class="flex items-center">
      <img :src="logo" alt="Company Logo" class="w-40 h-auto" />
      <div class="h-6 border-l border-gray-500 mx-4"></div>
      <span class="text-sm font-medium">CASPER</span>
    </div>
    <div class="relative inline-block text-left">
      <div class="flex items-center text-white py-2 cursor-pointer" @click="toggleDropdown">
        <div class="flex items-center justify-center w-8 h-8 bg-white text-gray-900 font-bold rounded-full mr-3">
          {{ getInitials(name) }}
        </div>
        <div class="flex flex-col">
          <span class="text-sm font-semibold">{{ name }}</span>
        </div>
        <div class="ml-2 text-xs">&#9662;</div>
      </div>
      <ul v-if="dropdownVisible"
        class="absolute right-0 mt-2 w-28 secondary-bg text-white border border-gray-600 rounded-md shadow-lg z-50">
        <li class="px-4 py-1 text-sm hover:bg-purple-600 hover:text-white cursor-pointer" @click="logout">
          Logout
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      logo: require("@/assets/img/fe/kearney-logo-white.png"),
      dropdownVisible: false
    }
  },
  methods: {
    logout() {
      this.dropdownVisible = false;
      this.$router.push("/");
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },

    getLoginData() {
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      if (loginData && loginData.name) {
        this.name = loginData.name;
      }
    },
    getInitials(name) {
      if (!name) return "";
      return name
        .split(" ")
        .map(word => word[0].toUpperCase())
        .join("");
    },
  },
  created() {
    this.getLoginData();
  },
}
</script>