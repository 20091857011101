export default {
  namespaced: true, // Important: Use namespaced to avoid conflicts
  state: {
    dashbaordData: {},
    existingFileUrl: null
  },
  mutations: {
    SET_DASHBOARD_DATA(state, data) {
      state.dashbaordData = data;
    },
    SET_FILE_URL(state, data) {
      state.existingFileUrl = data;
    }
  },
  actions: {
    setGeneratedDashboardData({ commit }, data) {
      console.log(data, 'Dashboard Store');
      commit("SET_DASHBOARD_DATA", data);
    },
    setExistingFileUrl({ commit }, data) {
      commit("SET_FILE_URL", data);
    },
  },
  getters: {
    dashboardData: (state) => state.data,
  },
};
