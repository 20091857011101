<template>
    <div class="file-container flex items-center justify-between secondary-bg text-white p-4 rounded-lg shadow-lg mt-6">
      <div class="flex items-center space-x-4">
        <div class="file-icon bg-blue-500 text-center text-white rounded-lg w-12 h-12 flex items-center justify-center font-bold">
          {{ fileExtension.toUpperCase() }}
        </div>
        <div>
          <p class="file-name font-semibold">{{ fileName }}</p>
          <p class="file-size text-sm text-gray-400">{{ fileSize }}</p>
        </div>
      </div>
      <button
        @click="downloadFile"
        class="download-button bg-purple-600 hover:bg-purple-500 text-sm text-white px-3 py-1 rounded-[3px] shadow-md"
      >
        Download
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: "FileDisplay",
    props: {
      fileName: {
        type: String,
        required: true,
      },
      fileSize: {
        type: String,
        required: false,
        default: "Unknown size",
      },
      fileUrl: {
        type: String,
        required: true,
      },
    },
    computed: {
      fileExtension() {
        return this.fileName.split(".").pop();
      },
    },
    methods: {
      downloadFile() {
        const link = document.createElement("a");
        link.href = this.fileUrl;
        link.download = this.fileName; // The default name for the downloaded file
        link.click();
      },
    },
  };
  </script>
  
  <style scoped>
  /* Optional: You can add custom styles here if needed */
  </style>
  