<template>
  <div v-if="Object.keys(allTopics).length > 0" class="mt-10 mb-4 flex flex-col justify-center items-center">
    <div class="w-full">
      <div class="mb-8">
        <h1 class="text-lg font-bold text-white">Topics Generated</h1>
        <p class="text-gray-400 text-[0.875rem]">
          You can edit the topics or subtopics as per the need.
        </p>
        <p class="text-gray-400 mt-2 text-[0.875rem]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
          ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
          fugiat nulla pariatur.
        </p>
      </div>
      <div class="grid grid-cols-2 gap-6">
        <div v-for="(subTopics, topic, index) in allTopics" :key="index" class="p-4 rounded-lg secondary-bg">
          <div class="flex justify-between items-center mb-2">
            <div v-if="editingTopicIndex === index">
              <input v-model="editingTopic" class="p-1 rounded" type="text" />
            </div>
            <div v-else>
              <span class="font-bold text-[1rem] text-white">{{ topic }}</span>
            </div>
            <div class="flex space-x-4">
              <button class="text-gray-400 hover:text-gray-300" @click="toggleEditTopic(index, topic)">
                <EditIcon v-if="editingTopicIndex !== index" />
                <span class="text-xs" v-else>Save</span>
              </button>
              <button class="text-red-500 hover:text-red-400" @click="deleteTopic(index, topic)">
                <TrashIcon />
              </button>
            </div>
          </div>
          <hr class="border-black my-2" />
          <div class="pl-4">
            <div v-for="(subtopic, subIndex) in subTopics" :key="subIndex"
              class="flex justify-between items-center mb-2">
              <div v-if="editingSubTopicIndex === `${index}-${subIndex}`">
                <input v-model="editingSubTopic" class="p-1 rounded" type="text" />
              </div>
              <div v-else>
                <span class="text-[1rem] text-white">{{ subtopic }}</span>
              </div>
              <div class="flex space-x-4">
                <button class="text-gray-400 hover:text-gray-300"
                  @click="toggleEditSubTopic(index, subIndex, subtopic)">
                  <EditIcon v-if="editingSubTopicIndex !== `${index}-${subIndex}`" />
                  <span class="text-xs" v-else>Save</span>
                </button>
                <button class="text-red-500 hover:text-red-400" @click="deleteSubTopic(topic, subIndex)">
                  <TrashIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 flex justify-start space-x-4">
        <button v-if="!loading" @click="generateDashboard" :disabled="loading"
          class="py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 font-bold py-2 px-6 rounded-[3px]">
          {{ loading ? "Loading..." : "Approve topics and subtopics" }}
        </button>
        <button v-if="!loading" @click="goToDashboard" :disabled="!dashbaordData || Object.keys(dashbaordData).length === 0"
          class="py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 font-bold py-2 px-6 rounded-[3px]">
          View Insights
        </button>

        <div v-if="loading" class="mt-4 w-full">
          <div class="w-full bg-gray-700 rounded-full h-3 relative">
            <div class="bg-purple-500 h-full rounded-full transition-all duration-500"
              :style="{ width: progressPercentage + '%' }"></div>
          </div>
          <p class="mt-2 text-white font-medium">
            {{ progressLabel }}...
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TrashIcon from "../Icons/TrashIcon.vue";
import EditIcon from "../Icons/EditIcon.vue";
import axios from "axios";

export default {
  components: {
    TrashIcon,
    EditIcon
  },
  props: {
    projectId: {
      type: [String, Number], // Adjust type according to your needs
      required: true, // projectId is mandatory
    },
    projectUploadId: {
      type: [String, Number], // Adjust type according to your needs
      required: false, // projectUploadId is optional
      default: null, // Default value when not provided
    },
  },
  data() {
    return {
      editingTopicIndex: null,
      editingTopic: "",
      editingSubTopicIndex: null,
      editingSubTopic: "",
      loading: false,
      progress: 0,
      statuses: [
        "pending",
        "processing",
        "generating sentiment score",
        "generating dashboard",
        "generating recommendation",
        "completed",
      ],
      currentStatus: 'pending'
    }
  },
  computed: {
    ...mapState("topics", ["topics", "project_upload_id"]),
    ...mapState("dashboard", ["dashbaordData"]),
    allTopics() {
      console.log(this.topics, 'allTopics');
      return this.topics?.result || this.topics || [];
    },
    progressPercentage() {
      const index = this.statuses.indexOf(this.currentStatus);
      if (index !== -1) {
        return ((index + 1) / this.statuses.length) * 100;
      }
      return 0;
    },
    progressLabel() {
      return this.currentStatus
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },

  },
  methods: {
    ...mapActions("topics", ["setGeneratedTopics", "deleteTopic", "deleteSubTopic"]),
    ...mapActions("dashboard", ["setGeneratedDashboardData", "setExistingFileUrl"]),
    toggleEditTopic(index, topic) {
      if (this.editingTopicIndex === index) {
        // Save changes
        const allTopicsKeys = Object.keys(this.allTopics);
        const newKey = this.editingTopic;
        const oldKey = allTopicsKeys[index];
        if (newKey && newKey !== oldKey) {
          this.$store.dispatch("topics/updateTopic", { oldKey, newKey, subtopics: this.allTopics[oldKey] });
        }
        this.editingTopicIndex = null;
        this.editingTopic = "";
      } else {
        // Enable editing
        this.editingTopicIndex = index;
        this.editingTopic = topic;
      }
    },
    toggleEditSubTopic(topicIndex, subIndex, subtopic) {
      const subKey = `${topicIndex}-${subIndex}`;
      if (this.editingSubTopicIndex === subKey) {
        // Save changes
        const allTopicsKeys = Object.keys(this.allTopics);
        const topicKey = allTopicsKeys[topicIndex];
        if (this.editingSubTopic) {
          this.$store.dispatch("topics/updateSubTopic", { topicKey, subIndex, subtopic: this.editingSubTopic });
        }
        this.editingSubTopicIndex = null;
        this.editingSubTopic = "";
      } else {
        // Enable editing
        this.editingSubTopicIndex = subKey;
        this.editingSubTopic = subtopic;
      }
    },
    deleteTopic(index, topic) {
      this.$store.dispatch("topics/deleteTopic", topic);
    },
    deleteSubTopic(topic, subIndex) {
      this.$store.dispatch("topics/deleteSubTopic", { topic, subIndex });
    },
    goToDashboard() {
      this.$emit('dashboardGenerated', this.dashbaordData);
    },
    async generateDashboard() {
      const fileName = sessionStorage.getItem('uploadedFileName');
      const selectedCategory = sessionStorage.getItem('selectedCategory');
      const industryName = sessionStorage.getItem('industryName');
      const reviewColumn = sessionStorage.getItem('reviewColumn');
      const idColumn = sessionStorage.getItem('idColumn');

      let url = window.location.pathname;
      let lastSegment = url.substring(url.lastIndexOf('/') + 1);
      console.log(this.project_upload_id + 'a', this.projectUploadId + 'b', lastSegment + 'c')
      const params = {
        topics: this.topics?.result,
        project_upload_id: this.project_upload_id || this.projectUploadId || lastSegment,
        fileName: fileName,
        category: selectedCategory,
        industryName: industryName,
        reviewColumn: reviewColumn,
        idColumn: idColumn
      };

      try {
        this.loading = true;
        this.progress = 0;

        await axios.post(process.env.VUE_APP_API_BASE_URL + "/api/start");
        const response = await axios.post(process.env.VUE_APP_API_BASE_URL + "/api/generate-dashboard", params);
        await this.refreshStatus();

        const task_id = response?.data?.task_id
        localStorage.setItem('task_id', task_id);

        console.log(response, 'response')

        const workerResponse = await axios.get(process.env.VUE_APP_API_BASE_URL + "/api/start-worker");
        console.log('this.project_upload_id: 221', this.project_upload_id);

        if (workerResponse?.data?.status && workerResponse?.data?.status === 'running') {
          this.setGeneratedDashboardData({});
          this.statusPollingInterval = setInterval(() => {
            this.fetchTaskStatus();
          }, 5000);
        }
      } catch (error) {
        console.error(error);
        alert("Failed to generate topics.");
        this.loading = false;
      }

      console.log(this.topics?.result, 'topics')
      console.log(fileName)
      console.log(selectedCategory)
      console.log(industryName)
    },
    async fetchTaskStatus() {
      try {
        console.log("this.project_upload_id: 242", this.project_upload_id);
        let url = window.location.pathname;
        let lastSegment = url.substring(url.lastIndexOf("/") + 1);

        const projectUploadIdentifier =
          this.project_upload_id || this.projectUploadId || lastSegment;

        let task_id = localStorage.getItem("task_id");

        if (task_id) {
          const response = await axios.get(
            `${process.env.VUE_APP_API_BASE_URL}/api/task-status/${task_id}/${projectUploadIdentifier}`
          );

          console.log(response, 'response?.data?.status')

          if (
            response?.data?.status === "success" || response?.data?.status === "completed" &&
            response?.data?.data?.details
          ) {
            console.log(
              response?.data?.data?.details,
              "response?.data?.data?.details"
            );
            if (
              Object.keys(response?.data?.data?.details).length > 0 &&
              response?.data?.data?.details != "{}" &&
              response?.data?.data?.details != null &&
              response?.data?.data?.details != "null"
            ) {
              this.setGeneratedDashboardData(
                JSON.parse(response?.data?.data?.details)
              );
              this.$emit(
                "dashboardGenerated",
                JSON.parse(response?.data?.data?.details)
              );
              this.loading = false;
              clearInterval(this.statusPollingInterval); // Stop polling when successful
            }
          } else if (response?.data?.status) {
            this.currentStatus = response?.data?.status; // Update the status dynamically
          } else {
            console.error("Error fetching task status:", response.data.message);
          }
        } else {
          console.log("Task ID not found in localStorage.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async refreshStatus() {
      try {
        // Fetch the current progress from the Flask server
        const response = await axios.get(process.env.VUE_APP_API_BASE_URL + "/api/progress");
        this.progress = response.data.progress;

        // Stop loading if progress reaches 100%
        if (this.progress >= 100) {
          this.loading = false;
        }
      } catch (error) {
        console.error("Error fetching progress:", error);
      }
    },
  },
  mounted() {
    if (this.projectUploadId) {
      // Fetch the current progress from the Flask server
      axios.get(process.env.VUE_APP_API_BASE_URL + "/api/project-uploads/get-dashboard-data/" + this.projectUploadId)
        .then(response => {
          console.log(response.data, 'response');  // This will log the actual data
          this.dashbaordData = response.data?.dashboard_data; // Assign the data to a property

          const topicString = response.data?.topics.replace(/```json|```/g, '');

          this.setGeneratedDashboardData(JSON.parse(response.data?.dashboard_data));
          this.setGeneratedTopics(JSON.parse(topicString));
          this.setExistingFileUrl(response.data?.file_url)

          // this.$emit('dashboardGenerated', JSON.parse(response.data?.dashboard_data));
          clearInterval(this.statusPollingInterval);  // Stop polling when successful
          console.log(this.dashbaordData, 'this.dashbaordData')
          // Call fetchTaskStatus every 5 seconds
          // if (!this.dashbaordData || Object.keys(this.dashbaordData).length === 0) {
          //   this.statusPollingInterval = setInterval(() => {
          //     this.fetchTaskStatus();
          //   }, 5000);
          // }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } else {
      this.setGeneratedDashboardData({});
      this.setGeneratedTopics({});
      this.setExistingFileUrl();
    }
  }
};
</script>

<style scoped>
.secondary-bg {
  background-color: #2b2b2b;
}
</style>
