<template>
  <div>
    <canvas ref="lineChart"></canvas>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { onMounted, ref, watch } from "vue";

// Register all required Chart.js components
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LineController, CategoryScale, LinearScale);

export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const lineChart = ref(null);
    let chartInstance = null;

    const renderChart = () => {
      const ctx = lineChart.value.getContext("2d");

      // Parse the chart data
      const labels = props.chartData.data.map((item) => item.date);
      const achievedData = props.chartData.data.map((item) => item.achieved);
      const targetData = props.chartData.data.map((item) => item.target);

      // Destroy existing chart instance to avoid duplication
      if (chartInstance) {
        chartInstance.destroy();
      }

      // Create a new chart instance
      chartInstance = new ChartJS(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Achieved",
              data: achievedData,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              tension: 0.4,
              fill: false,
            },
            {
              label: "Avg Sentiment",
              data: targetData,
              borderColor: "rgba(255, 99, 132, 1)",
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              tension: 0.4,
              fill: false,
              borderDash: [10, 5],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                color: "white",  // Set legend text color to white
              },
            },
          },
          scales: {
            x: {
              ticks: {
                color: "white",  // Set x-axis label color to white
              },
            },
            y: {
              ticks: {
                color: "white",  // Set y-axis label color to white
              },
            },
          },
          tooltip: {
            titleColor: "white",  // Set tooltip title color to white
            bodyColor: "white",   // Set tooltip body text color to white
          },
        },
      });
    };

    // Watch for prop changes and re-render the chart
    watch(
      () => props.chartData,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          renderChart();
        }
      },
      { deep: true }
    );

    onMounted(() => {
      renderChart();
    });

    return {
      lineChart,
    };
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  max-height: 50vh;
  min-height: 30vh;
}
</style>
