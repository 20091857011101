<template>
    <div class="min-h-screen bg-black">
      <Header />
      <header class="flex justify-between items-center p-4 mt-20 ml-28">
        <div class="flex justify-between items-center mb-4 mt-8">
          <div class="relative">
            <!-- <input type="text" placeholder="Search" class="w-full py-2 px-3 rounded-lg border border-gray-700 focus:outline-none focus:border-purple-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute top-1/2 right-2 transform -translate-y-1/2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0" />
            </svg> -->
            <span class="text-white text-2xl font-bold" v-if="projectUploads && projectUploads.length > 0">{{ projectUploads[0]?.title }}</span>
          </div>
        </div>
        <button class="mt-8 mr-28 bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded" @click="goToTool">
          + Go for New Analysis
        </button>
      </header>
  
      <main class="container mx-auto p-4">
  
        <div class="grid grid-cols-1 gap-4" v-if="projectUploads && projectUploads.length">
          <div v-for="(file, index) in projectUploads" :key="index" class="secondary-bg rounded-lg p-4">
            <h3 class="text-lg font-semibold mb-2 text-white">{{ file.title }}</h3>
            <p class="text-sm text-white">{{ file.file_url }}</p>
            <button class="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded mt-4"
            @click="goToExistingTool(file.id)">
              View Analysis >
            </button>
          </div>
        </div>
        <div v-else class="mt-12 flex justify-center items-center">
            <img :src="emptyUpload" alt="No uploads available" class="w-1/3">
        </div>
      </main>
    </div>
  </template>
  
  <script>
  import Header from '@/components/Header.vue';
  import axios from 'axios';
  import emptyUpload from "@/assets/img/fe/empty-upload.svg";

  export default {
    components: {
      Header
    },
    props: ['projectId'],
    data() {
      return {
        projectUploads: {},
        emptyUpload
      };
    },
    methods: {
      async fetchProjectData() {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/project_uploads/${this.projectId}`);
          this.projectUploads = response.data;
        } catch (error) {
          console.error('Error fetching projectUploads data:', error);
        }
      },
      async goToTool() {
        // Handle file upload API call here
        this.$router.push({ name: 'dataUpload', params: { projectId: this.projectId } });
      },
      async goToExistingTool(projectUploadId) {
        this.$router.push({ 
            name: 'dataUpload', 
            params: { 
                projectId: this.projectId, 
                projectUploadId: projectUploadId 
            },
        });
      },
    },
    created() {
      this.fetchProjectData();
    }
  };
  </script>
  