<template>
  <div class="min-h-screen bg-black pb-16">
    <Header />
    <div class="flex justify-between items-center mt-16 p-16">
      <div>
        <h1 class="text-2xl font-bold text-white">{{ dynamicGreeting }}</h1>
        <p class="text-sm text-gray-400">Welcome back! Let's get started.</p>
      </div>
      <div>
        <div
          class="relative flex justify-between items-center w-full rounded-full p-1 my-2 space-x-4 secondary-bg border border-purple-600">
          <button @click="selectedOption = 'Data Upload'" :class="[
            'px-6 py-2 h-full flex items-center justify-center rounded-full text-sm font-medium transition-all',
            selectedOption === 'Data Upload' ? 'bg-purple-600 text-white' : 'text-white',
          ]">
            Data Upload
          </button>
          <button 
            @click="selectedOption = 'Dashboard'"
            :disabled="!dashbaordData?.total_reviews"
            :class="[
              'px-6 py-2 h-full flex items-center justify-center rounded-full text-sm font-medium transition-all',
              selectedOption === 'Dashboard' ? 'bg-purple-600' : '',
              dashbaordData?.total_reviews ? 'text-white' : 'text-gray-400 opacity-50 cursor-not-allowed'
            ]">
            Dashboard
          </button>
          <button @click="selectedOption = 'Image Generation'" :class="[
            'px-6 py-2 h-full flex items-center justify-center rounded-full text-sm font-medium transition-all',
            selectedOption === 'Image Generation' ? 'bg-purple-600 text-white' : 'text-white',
          ]">
            Image Generation
          </button>
        </div>
      </div>
    </div>

    <div class="mx-16">
      <MediaUploadComponent :projectId="projectId" :projectUploadId="projectUploadId" v-if="selectedOption === 'Data Upload'" />
      <TopicsGeneratedComponent :projectId="projectId" :projectUploadId="projectUploadId" @dashboardGenerated="handleDashboardGenerated" v-if="selectedOption === 'Data Upload'" />
      <DashboardTab :projectId="projectId" :projectUploadId="projectUploadId" v-if="selectedOption === 'Dashboard'" />
      <ImageGenerationTab :projectId="projectId" :projectUploadId="projectUploadId" v-if="selectedOption === 'Image Generation'" />
    </div>
  </div>
</template>

<script>
import MediaUploadComponent from '@/components/DataUpload/GenerateTopics.vue';
import TopicsGeneratedComponent from '@/components/DataUpload/Topics.vue';
import DashboardTab from "@/components/Tabs/DashboardTab.vue";
import ImageGenerationTab from "@/components/Tabs/ImageGenerationTab.vue";
import { mapState } from "vuex";
import Header from './Header.vue';

export default {
  name: "DataUpload1",
  components: {
    Header,
    DashboardTab,
    ImageGenerationTab,
    MediaUploadComponent,
    TopicsGeneratedComponent
  },
  props: {
  projectId: {
    type: [String, Number], // Adjust type according to your needs
    required: true, // projectId is mandatory
  },
  projectUploadId: {
    type: [String, Number], // Adjust type according to your needs
    required: false, // projectUploadId is optional
    default: null, // Default value when not provided
  },
},
  data() {
    return {
      selectedOption: "Data Upload", // Default selected option
      name: "",
      dropdownVisible: false
    };
  },
  created() {
    this.getLoginData();
  },
  computed: {
    ...mapState("dashboard", ["dashbaordData"]),
    dynamicGreeting() {
      const hours = new Date().getHours();
      let greeting = "Good Morning"; 

      if (hours >= 12 && hours < 18) {
        greeting = "Good Afternoon";
      } else if (hours >= 18 && hours < 24) {
        greeting = "Good Evening";
      }
      
      return `${greeting}, ${this.name}!`;
    }
  },
  methods: {
    getLoginData() {
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      if (loginData && loginData.name) {
        this.name = loginData.name;
      }
    },
    toggleButton(type) {
      this.selectedOption = type;
    },
    handleDashboardGenerated(data) {
      this.selectedOption = 'Dashboard';
    }
  }
};
</script>

<style>
.secondary-bg {
  background: #1e1e1e;
}
</style>
