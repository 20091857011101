import { createStore } from "vuex";
import topics from "./modules/topics";
import dashboard from "./modules/dashboard";

const store = createStore({
  modules: {
    topics,
    dashboard,
  },
});

export default store;
