<template>
  <div class="min-h-screen bg-black">
    <Header />
    <div class="flex justify-between items-center mt-16 p-16">
      <div>
        <h1 class="text-3xl font-bold text-white">{{ dynamicGreeting }}</h1>
        <p class="text-sm text-gray-400">Welcome back! Let's get started.</p>
      </div>
      <div>
        <div class="flex bg-purple-500 text-white rounded-[3px] overflow-hidden">
          <button class="px-12 py-3 focus:outline-none w-full hover:bg-purple-500 transition whitespace-nowrap"
            aria-label="Select Image Generation" @click="togglePopup">
            + Create Project
          </button>
        </div>
      </div>
    </div>
    <div class="mx-16">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        <ProjectCard v-for="(project, index) in projects" :key="index" :tags="project.tags" :title="project.title"
          :subtitle="project.description" :date="project.created_at" @click="goToProjectUpload(project.id)" />
      </div>
    </div>
    <div v-if="isPopupVisible" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div class="bg-black rounded-lg shadow-md p-6 w-1/2 relative">
        <button @click="togglePopup"
          class="absolute top-2 right-2 text-white bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center">
          X
        </button>
        <h2 class="text-xl font-semibold text-white mb-6">Create Project</h2>
        <form @submit.prevent="createProject">
          <div class="w-full md:w-full pr-2 mb-12 md:mb-0">
            <label for="input1" class="text-white font-bold text-[0.875rem] mb-1 block">
              Project Name
            </label>
            <input id="input1" v-model="projectName" type="text" placeholder="Project Name"
              class="input-bg w-full px-4 py-2 text-white rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]" />
          </div>
          <div class="w-full md:w-full mt-4 pr-2 mb-4 md:mb-0">
            <label for="input1" class="text-white font-bold text-[0.875rem] mb-2 block">
              Project Description
            </label>
            <textarea id="input1" v-model="projectDescription" rows="4" placeholder="Project Description"
              class="input-bg w-full px-4 py-2 text-white rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]" />
          </div>
          <div class="w-full md:w-full mt-4 pr-2 mb-4 md:mb-0">
            <label for="tags" class="text-white font-bold text-[0.875rem] mb-2 block">
              Tags (separate with commas)
            </label>
            <input id="tags" v-model="tags" type="text" placeholder="e.g. Tag1, Tag2, Tag3"
              class="input-bg w-full px-4 py-2 text-white rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]" />
          </div>
          <div class="flex">
            <button type="submit" class="bg-purple-500 text-white px-4 py-2 rounded-[3px] mt-4 hover:bg-purple-600 transition">
              Create New
            </button>
          </div>
        </form>
        <div v-if="errorMessage" class="mt-4 text-red-500 text-sm">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import ProjectCard from '@/components/ProjectCard.vue';
import axios from 'axios';

export default {
  name: "DataUpload",
  components: {
    Header,
    ProjectCard
  },
  data() {
    return {
      logo: require("@/assets/img/fe/kearney-logo-white.png"),
      name: '',
      projects: [],
      projectName: '',
      projectDescription: '',
      tags: '',
      isPopupVisible: false,
      errorMessage: '',
    };
  },
  methods: {
    getLoginData() {
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      if (loginData && loginData.name) {
        this.name = loginData.name;
      }
    },
    togglePopup() {
      this.isPopupVisible = !this.isPopupVisible;
      this.errorMessage = '';
    },
    async createProject() {
      const projectData = {
        title: this.projectName,
        description: this.projectDescription,
        tags: this.tags,
        created_by: 1
      };

      try {
        const response = await axios.post(process.env.VUE_APP_API_BASE_URL+"/api/projects", projectData);
        
        if (response.status === 201) {
          console.log('Project Created:', response.data);
          this.togglePopup();
          this.fetchProjects();
        }
      } catch (error) {
        console.error('Error creating project:', error);
        this.errorMessage = 'Failed to create project. Please try again.';
      }
    },
    async fetchProjects() {
      try {
        const response = await axios.get(process.env.VUE_APP_API_BASE_URL+"/api/projects");
        this.projects = response.data;
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    },
    goToProjectUpload(projectId) {
      this.$router.push({ name: 'projectUpload', params: { projectId } });
    }
  },
  computed: {
    dynamicGreeting() {
      const hours = new Date().getHours();
      let greeting = "Good Morning";

      if (hours >= 12 && hours < 18) {
        greeting = "Good Afternoon";
      } else if (hours >= 18 && hours < 24) {
        greeting = "Good Evening";
      }

      return `${greeting}, ${this.name}!`;
    }
  },
  created() {
    this.getLoginData();
    this.fetchProjects();
  }
};
</script>

<style scoped>
.secondary-bg {
  background: #1e1e1e;
}

.border-black {
  border: 1px solid #000;
}
</style>
