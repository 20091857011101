import { createRouter, createWebHistory } from "vue-router";
import LoginPage from '@/views/LoginPage.vue'
import DataUpload from '@/components/DataUpload.vue'
import ProjectPage from '@/views/ProjectPage.vue'
import ProjectUpload from '@/views/ProjectUpload.vue'
const routes = [
  {
    path: '/',
    name: "login",
    component: LoginPage
  },
  {
    path: '/data-upload/:projectId/:projectUploadId?',
    name: "dataUpload",
    component: DataUpload,
    props: true
  },
  {
    path: '/projects',
    name: "project",
    component: ProjectPage
  },
  {
    path: '/project-uploads/:projectId',
    name: "projectUpload",
    component: ProjectUpload,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
