<template>
  <div>
    <!-- Header Section -->
    <div v-if="!projectUploadId">
      <h1 class="text-lg font-bold text-white">Media Upload</h1>
      <p class="text-sm text-gray-400">
        Add your documents here, and you can upload up to 5 files max
      </p>
    </div>
    <!-- Upload Section -->
    <div v-if="!projectUploadId"
      class="p-16 flex flex-col justify-center items-center border-2 border-dashed border-gray-500 secondary-bg rounded-lg mt-8">
      <img :src="uploadIcon" alt="Upload Icon" class="mb-4" />
      <p class="text-white text-lg mb-2">
        Drag your file(s) or
        <span class="text-purple-500 cursor-pointer hover:underline" @click="triggerFileInput">
          browse
        </span>
      </p>
      <p class="text-gray-400 text-sm mb-4">Max 10 MB files are allowed</p>

      <input type="file" id="file-upload" class="hidden" @change="handleFileUpload" ref="fileInput" accept=".csv, .xlsx" />
    </div>

    <!-- Progress Bar -->
    <div v-if="uploading" class="flex flex-col justify-center items-center secondary-bg mt-8">
      <div class="relative w-full h-4 bg-gray-700 rounded-full overflow-hidden">
        <div class="absolute h-full bg-purple-500" :style="{ width: `${progress}%` }"></div>
      </div>
    </div>

    <FileDisplay
      v-if="showTable"
      :fileName="fileName"
      :fileSize="fileSize"
      :fileUrl="fileUrl"
    />

    <!-- Table Section -->
    <div v-if="showTable" class="w-full mt-8 bg-black flex flex-col space-y-8">
      <div class="flex justify-center items-center">
        <div class="rounded-md overflow-hidden w-full">
          <div class="overflow-x-auto">
            <table class="p-4 table-auto w-full border-collapse">
              <thead>
                <tr>
                  <th v-for="(header, index) in tableHeaders" :key="index"
                    class="bg-purple-600 text-white text-left px-4 py-2 text-sm whitespace-nowrap">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="secondary-bg text-gray-200" v-for="(row, index) in tableData" :key="index">
                  <td v-for="(value, colIndex) in row" :key="colIndex"
                    class="text-left px-4 py-2 text-sm whitespace-nowrap">
                    {{ typeof value === "string" ? truncate(value, 150) : value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Form Section -->
      <div class="flex flex-col justify-center items-center">
        <div class="w-full">
          <div class="flex flex-wrap mb-4">
            <div class="w-full md:w-1/3 pr-2 mb-4 md:mb-0">
              <label for="input1" class="text-white font-bold text-[0.875rem] mb-1 block">
                Enter Industry Name
              </label>
              <input id="input1" v-model="industryName" type="text" placeholder="Industry Name"
                class="input-bg w-full px-4 py-2 text-white rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]" />
            </div>
            <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <label for="input2" class="text-white font-bold text-[0.875rem] mb-1 block">
                Enter Company Name
              </label>
              <input id="input2" v-model="companyName" type="text" placeholder="Company Name"
                class="input-bg w-full px-4 py-2 text-white rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]" />
            </div>
            <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <label for="input3" class="text-white font-bold text-[0.875rem] mb-1 block">
                Select ID Columns
              </label>
              <select id="input3" v-model="selectedProduct"
                class="custom-dropdown rounded text-white input-bg w-full px-4 rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]">
                <option value="" disabled>Select Product Column</option>
                <option v-for="(item, index) in tableHeaders" :key="index" :value="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
          <!-- Row 2 -->
          <div class="flex flex-wrap mb-4">
            <div class="w-full md:w-1/3 pr-2">
              <label for="input4" class="text-white font-bold text-[0.875rem] mb-1 block">
                Select Review Columns
              </label>
              <multiselect v-model="selectedReview" :options="tableHeaders" :multiple="true" :close-on-select="false"
                placeholder="Select Review Columns" class="custom-multiselect" />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <label for="input5" class="text-white font-bold text-[0.875rem] mb-1 block">Select Category for
                Recommendation
                Level</label>
              <select v-model="selectedCategory"
                class="custom-dropdown rounded input-bg w-full px-4 text-white rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]">
                <option value="" disabled>Select Category Column</option>
                <option class="tooltip" :data-tooltip="item" v-for="(item, index) in tableHeaders" :key="index"
                  :value="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>

          <!-- Generate Topics Button -->
          <div class="flex">
            <button @click="generateTopics" :disabled="loading"
              class="px-12 py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white rounded-[3px] font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500">
              {{ loading ? "Loading..." : "Generate Topics" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import FileDisplay from "@/components/FileDisplay.vue";

export default {
  props: {
    projectId: {
      type: [String, Number], // Adjust type according to your needs
      required: true, // projectId is mandatory
    },
    projectUploadId: {
      type: [String, Number], // Adjust type according to your needs
      required: false, // projectUploadId is optional
      default: null, // Default value when not provided
    },
  },
  data() {
    return {
      uploadIcon: require("@/assets/img/fe/upload-icon.svg"),
      progress: 0,
      uploading: false,
      showTable: false,
      tableData: [],
      categories: [],
      products: [],
      industryName: "",
      companyName: "",
      selectedProduct: "",
      selectedReview: [],
      selectedCategory: "",
      file: null,
      loading: false,
      generatedTopics: [],
      tableHeaders: [],
      fileName: '',
      fileSize: '',
      fileType: '',
      fileUrl: '',
    };
  },
  components: {
    Multiselect,
    FileDisplay
  },
  methods: {
    ...mapActions("topics", ["setGeneratedTopics", "setProjectUploadId"]), // Map the action from the topics module
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    formatFileSize(size) {
      const kilobytes = size / 1024;
      if (kilobytes < 1024) {
        return `${Math.round(kilobytes)}kb`;
      } else {
        const megabytes = kilobytes / 1024;
        return `${Math.round(megabytes)}MB`;
      }
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (this.file) {
        this.fileName = this.file.name;
        this.fileSize = this.formatFileSize(this.file.size);

        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          this.parseExcel(workbook);
        };
        reader.readAsArrayBuffer(this.file);
        this.startUpload();
      }
    },
    startUpload() {
      this.uploading = true;
      this.progress = 0;
      const interval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 10;
        } else {
          clearInterval(interval);
          setTimeout(() => {
            this.uploading = false;
            this.showTable = true;
            this.fileUrl = URL.createObjectURL(this.file);
          }, 100);
        }
      }, 100);
    },
    parseExcel(workbook) {
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = jsonData[0];
      const data = jsonData.slice(1).map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index] || "";
        });
        return obj;
      });
      const uniqueCategories = this.getUniqueCategories(data);
      this.categories = uniqueCategories

      const uniqueProducts = this.getUniqueProductIds(data);
      this.products = uniqueProducts

      console.log(this.products)
      this.tableData = data.slice(0, 7); // Take only the first 7 records
      this.tableHeaders = headers
      console.log(this.tableData, 'this.tableData')
    },
    getUniqueCategories(data) {
      const categories = data.map(item => item.Category); // Extract all categories
      const uniqueCategories = [...new Set(categories)];  // Get unique categories
      return uniqueCategories;
    },
    getUniqueProductIds(data) {
      const products = data.map(item => item['Product Name']); // Extract all categories
      const uniqueProducts = [...new Set(products)];  // Get unique categories
      return uniqueProducts;
    },
    setTopicsFromApiResponse(response) {
      const parsedTopics = Object.entries(response.result).map(([topic, subtopics]) => ({
        name: topic,
        subtopics: subtopics,
      }));

      this.topics = parsedTopics; // Update the topics for rendering
    },
    async generateTopics() {
      if (!this.industryName || !this.companyName || !this.file) {
        alert("Please fill all required fields and upload a file.");
        return;
      }

      this.loading = true;
      const formData = new FormData();
      formData.append("industryName", this.industryName);
      formData.append("companyName", this.companyName);
      formData.append("product", this.selectedProduct);
      formData.append("review", this.selectedReview);
      formData.append("file", this.file);

      console.log(this.file.name, 'this.file.name')

      sessionStorage.setItem('uploadedFileName', this.file.name);
      sessionStorage.setItem('industryName', this.industryName);
      sessionStorage.setItem('selectedCategory', this.selectedCategory);
      sessionStorage.setItem('reviewColumn', this.selectedReview);
      sessionStorage.setItem('idColumn', this.selectedProduct);

      try {
        const response = await axios.post(process.env.VUE_APP_API_BASE_URL + "/api/generate-topics/" + this.projectId, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        this.showTable = true;
        const topics = response?.data || [];

        this.setGeneratedTopics(topics);
        this.setProjectUploadId(topics);
      } catch (error) {
        console.error(error);
        alert("Failed to generate topics.");
      } finally {
        this.loading = false;
      }
    },
    truncate(text, length) {
      if (!text) return ""; // Handle empty or null values
      return text.length > length ? text.slice(0, length) + "..." : text;
    },
    // Fetch file details from the server or URL
    fetchFileDetails(fileUrl) {
      // Assuming the file URL points to a file accessible from the frontend
      // Use fetch to get metadata if possible
      this.fileUrl = fileUrl; // Set file URL

      // Extract metadata from the file
      this.extractFileMetadata(fileUrl);
    },
    async extractFileMetadata(fileUrl) {
      try {
        const response = await fetch(fileUrl, { method: 'HEAD' });
        
        if (response.ok) {
          // Get metadata from response headers
          this.fileName = fileUrl.split('/').pop(); // Get file name from URL
          this.fileSize = await this.getFileSize(response);
          this.fileType = response.headers.get('Content-Type'); // Get content type
          this.showTable = true; // Show the file display component
        } else {
          console.error('Failed to fetch file metadata');
        }
      } catch (error) {
        console.error('Error fetching file metadata:', error);
      }
    },

    // Get file size from headers
    async getFileSize(response) {
      const contentLength = response.headers.get('Content-Length');
      if (contentLength) {
        return this.formatFileSize(contentLength);
      } else {
        return 'Unknown size';
      }
    },


  },
  watch: {
    existingFileUrl(newUrl) {
      if (newUrl) {
        this.fetchFileDetails(newUrl); // Fetch file details if existingFileUrl is available
      }
    }
  },

  computed: {
    ...mapState("dashboard", ["existingFileUrl"]),
  }
};
</script>

<style>
.custom-dropdown {
  padding-top: 9.25px;
  padding-bottom: 9.25px;
}

.input-bg {
  background-color: #222127;
}

.secondary-bg {
  background-color: #2b2b2b;
}

.tooltip {
  position: relative;
}

.tooltip:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background: #333;
  /* color: #fff; */
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 100;
  font-size: 0.75rem;
  width: max-content;
}

/* Base multiselect styles */
.multiselect {
  background-color: #000000 !important;
  /* Gray background for dropdown */
  border: none;
}

/* Hover state for options */
.multiselect__element:hover {
  background-color: #9334e9 !important;
  /* Purple */
  color: white !important;
  /* White text */
}

/* Selected options */
.multiselect__element.multiselect__option--selected {
  background-color: #9334e9 !important;
  /* Purple */
  color: white !important;
  /* White text */
}

/* Selected tags */
.multiselect__tag {
  background-color: #9334e9 !important;
  /* Purple */
  color: white !important;
  /* White text */
  border: none;
}

.multiselect__tags {
  max-height: 33px !important;
  padding: 5px 40px 0 8px !important;
  border-radius: 3px !important;
  background: #222127 !important;
  border: none !important;
}

/* Customize dropdown arrow (if necessary) */
.multiselect__select {
  color: white;
  /* White arrow */
}

/* Highlighted options (when hovering or navigating with the keyboard) */
.multiselect__option--highlight {
  background-color: #9334e9 !important;
  /* Purple */
  color: white !important;
  /* White text */
}

.multiselect__option--highlight.multiselect__option {
  background-color: #9334e9 !important;
  /* Purple */
}

/* To ensure selected tags stay styled consistently */
.multiselect__tag {
  background-color: #9334e9 !important;
  color: white !important;
}

/* Override green default styling in dropdown */
.multiselect__option--highlight:hover,
.multiselect__element .multiselect__option--highlight {
  background-color: #9334e9 !important;
  /* Purple */
  color: white !important;
}

.multiselect__content-wrapper {
  color: white !important;
  background: #222127 !important;
  border: 1px solid grey !important;
  border-bottom: none !important;
}

.multiselect__input,
.multiselect__single {
  background-color: #222127 !important;
  color: white !important;
}
</style>
