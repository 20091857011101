<template>
  <div :class="tileClass + ' p-4 rounded-lg flex items-center justify-between'">
    <div>
      <h4 class="text-white text-sm mb-3">{{ title }}</h4>
      <p class="text-2xl font-bold">{{ value }}</p>
    </div>
    <div v-if="title=='Average Rating'" :class="value > 2.5 ? 'text-green-500' : 'text-red-500'">
      <div class="bg-white rounded-full p-2 flex items-center justify-center">
        <img height="15px" width="15px" :src="value > 2.5 ? arrowUp : arrowDown" alt="Arrow Icon" />
      </div>
    </div>
    <div v-if="title=='Average Sentiment'" :class="value > 0 ? 'text-green-500' : 'text-red-500'">
      <div class="bg-white rounded-full p-2 flex items-center justify-center">
        <img height="15px" width="15px" :src="value > 0 ? arrowUp : arrowDown" alt="Arrow Icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arrowUp: require("@/assets/img/fe/arrow-up.svg"),
      arrowDown: require("@/assets/img/fe/arrow-down.svg"),
    };
  },
  props: {
    title: String,
    value: String,
  },
  computed: {
    tileClass() {
      console.log(this.arrowDown); // Make sure the path is correct

      // Dynamically choose class based on the title
      switch (this.title.toLowerCase()) {
        case 'average rating':
          return 'average-rating-tile';
        case 'average sentiment':
          return 'average-sentiment-tile';
        default:
          return 'review-tile'; // Fallback class if title doesn't match
      }
    },
  }
};
</script>

<style scoped>
.review-tile {
  background: #B4805D;
}
.average-rating-tile {
  background: #4B457F;
}
.average-sentiment-tile {
  background: #29627C;
}
</style>
