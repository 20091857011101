<template>
  <div>
    <button type="submit"
      class="w-1/4 px-12 py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white rounded-[3px] font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
      @click="triggerFileInput">
      Upload File
    </button>
    <input type="file" id="image-upload" class="hidden" accept="image/*" @change="handleFileUpload" ref="fileInput" />
    <div class="py-6 w-full" v-if="image">
      <div class="mb-4 mt-6 w-1/2">
        <label for="brush-size" class="block text-white text-sm font-bold mb-2">Brush Size - {{ brushSize
          }}</label>
        <input type="range" v-model="brushSize" min="1" max="50" step="1" class="w-full accent-purple-500" />
        <button @click="undo"
          class="mt-4 px-6 py-1 bg-gray-500 text-white rounded-[3px] font-semibold shadow-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400">
          Undo
        </button>
      </div>
      <canvas ref="canvas" :style="canvasStyle" class="w-auto border border-gray-300" @mousedown="startDrawing"
        @mousemove="draw" @mouseup="stopDrawing">
      </canvas>
      <div class="w-1/2 mt-6 flex justify-between">
        <button @click="generateImage"
          class="w-1/2 px-6 py-3 bg-purple-600 text-white rounded-lg font-semibold shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
          Generate Image
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    recommendationChanges: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    model: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      image: null,
      originalImage: null,
      isDrawing: false,
      lastX: 0,
      lastY: 0,
      brushedAreas: new Set(),
      brushSize: 5,
      canvasStyle: {
        cursor: `url('/pencil-cursor.svg'), auto`,
        border: '1px solid black'
      }
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = new Image();
        this.image.src = e.target.result;
        this.image.onload = this.initializeCanvas;
      };
      reader.readAsDataURL(file);
    },
    initializeCanvas() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      canvas.width = this.image.width;
      canvas.height = this.image.height;
      ctx.drawImage(this.image, 0, 0);
      this.originalImage = canvas.toDataURL(); // Save the original image data
    },
    startDrawing(event) {
      this.isDrawing = true;
      [this.lastX, this.lastY] = [event.offsetX, event.offsetY];
      this.brushedAreas.add(`${this.lastX},${this.lastY}`);
    },
    draw(event) {
      if (!this.isDrawing) return;

      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      ctx.strokeStyle = 'black';
      ctx.lineJoin = 'round';
      ctx.lineWidth = this.brushSize;

      ctx.beginPath();
      ctx.moveTo(this.lastX, this.lastY);
      ctx.lineTo(event.offsetX, event.offsetY);
      ctx.stroke();

      [this.lastX, this.lastY] = [event.offsetX, event.offsetY];
      this.brushedAreas.add(`${this.lastX},${this.lastY}`);
    },
    stopDrawing() {
      this.isDrawing = false;
    },
    undo() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      const originalCanvas = new Image();
      originalCanvas.src = this.originalImage;
      originalCanvas.onload = () => {
        ctx.drawImage(originalCanvas, 0, 0);
        this.brushedAreas.clear();
      };
    },
    async generateImage() {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = this.image.width;
      canvas.height = this.image.height;

      // Fill entire canvas with white
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the original image
      ctx.globalCompositeOperation = 'destination-atop';
      ctx.drawImage(this.image, 0, 0);

      // Retain the brushed areas
      ctx.globalCompositeOperation = 'source-over';
      this.brushedAreas.forEach((coord) => {
        const [x, y] = coord.split(',').map(Number);
        ctx.fillStyle = 'white';
        ctx.fillRect(x - this.brushSize / 2, y - this.brushSize / 2, this.brushSize, this.brushSize);
      });

      const generatedImage = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = generatedImage;
      a.download = 'generated_image.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Convert the generated mask image to Base64
      const generatedImageBase64 = canvas.toDataURL('image/png').replace(/^data:image\/png;base64,/, '');

      // Convert the original image to Base64
      const originalImageBase64 = this.originalImage.replace(/^data:image\/png;base64,/, '');

      // Create the payload
      const payload = {
        mask_image: {
          bytes: generatedImageBase64,
          format: 'png',
          width: this.image.width,
          height: this.image.height
        },
        original_image: {
          bytes: originalImageBase64,
          format: 'png',
          width: this.image.width,
          height: this.image.height
        },
        prompt: {
          text: this.recommendationChanges,
          strength: 0.8,
          negative_prompt: 'blurry, distorted, low quality'
        },
        settings: {
          seed: 12345,
          num_inference_steps: 50,
          guidance_scale: 7.5,
          preserve_color: true
        }
      };

      // Send the payload using Axios
      try {
        const response = await axios.post('https://kearneysofac-dev.azure-api.net/casper-be/inpaint', payload);
        console.log('API Response:', response.data);
      } catch (error) {
        console.error('Error sending the payload:', error);
      }
    }
  },
};
</script>

<style scoped></style>