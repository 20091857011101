<template>
  <div class="text-[0.875rem] border border-gray-700 p-4 rounded-sm mb-4">
    <div class="flex items-center">
      <h4 class="font-semibold mr-2">{{ title }}</h4>
      <img 
        :src="getSentimentIcon" 
        alt="Sentiment Icon"
        class="w-5 h-5"
      />
    </div>
    <p class="text-gray-400">{{ getFormattedContent(content) }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      likeIcon: require("@/assets/img/fe/like.svg"),
      dislikeIcon: require("@/assets/img/fe/dislike.png"),
      neutralIcon: require("@/assets/img/fe/neutral-icon.svg"),
    };
  },
  props: {
    title: String,
    content: String,
    sentiment: Number,  
  },
  computed: {
    getSentimentIcon() {
      if (this.sentiment >= -1.0 && this.sentiment < -0.1) {
        // Dislike
        return this.dislikeIcon;
      } else if (this.sentiment >= -0.1 && this.sentiment  < 0.1) {
        // like
        return this.neutralIcon;
      } else {
        // Neutral
        return this.likeIcon;
      }
    },
  },
  methods: {
    getFormattedContent(content) {
      return content.replace(/_x000D_/g, ' ');
    }
  }
};
</script>
