<template>
  <div>
    <!-- Button to open the sidebar -->
    <div class="flex justify-end">
      <button
        @click="toggleSidebar"
        class="bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 font-bold py-2 px-6 rounded-[3px] mb-2"
      >
        Filters
      </button>
    </div>
    <div class="flex justify-end">
      <button
        @click="downloadFile('dashboard')"
        class="bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 font-bold py-2 px-6 rounded-[3px] mb-2"
      >
        Download
      </button>
    </div>
    <!-- Sidebar -->
    <div
      v-if="isSidebarOpen"
      class="fixed top-0 right-0 h-full w-1/4 sidebar shadow-lg z-50 flex flex-col p-6"
    >
      <!-- Close button -->
      <button
        @click="toggleSidebar"
        class="self-end mb-4 text-gray-500 hover:text-gray-700"
      >
        &times;
      </button>
      <div class="text-white mt-[10%] mb-8">
        <div class="text-xl">Select Options</div>
        <div class="text-sm filter-subtitle">Select the options below for an optimised view</div>
      </div>

      <!-- Dropdowns -->
      <div class="mb-4">
        <label for="topic" class="block text-gray-700 mb-2">Topic</label>
        <select v-model="selectedTopic" @change="updateSubtopics" class="w-full border border-gray-300 rounded px-3 py-2">
          <option value="">Select Topic</option>
          <option v-for="(subtopics, topic) in topics.result" :key="topic" :value="topic">{{ topic }}</option>
        </select>
      </div>
      <!-- Subtopic Dropdown -->
      <div class="mb-4" v-if="selectedTopic">
        <label for="subtopic" class="block text-gray-700 mb-2">Subtopic</label>
        <select v-model="selectedSubtopic" class="w-full border border-gray-300 rounded px-3 py-2">
          <option value="">Select Subtopic</option>
          <option v-for="subtopic in filteredSubtopics" :key="subtopic" :value="subtopic">{{ subtopic }}</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="topic" class="block text-gray-700 mb-2">Product Category</label>
        <select v-model="selectedCategory" class="w-full border border-gray-300 rounded px-3 py-2">
          <option value="">Select Category</option>
          <option v-for="(subtopics, topic) in topics.result" :key="topic" :value="topic">{{ topic }}</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="topic" class="block text-gray-700 mb-2">Product Name</label>
        <select v-model="selectedProduct" class="w-full border border-gray-300 rounded px-3 py-2">
          <option value="">Select Product</option>
          <option v-for="(subtopics, topic) in topics.result" :key="topic" :value="topic">{{ topic }}</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="topic" class="block text-gray-700 mb-2">Website</label>
        <select v-model="selectedWebsite" class="w-full border border-gray-300 rounded px-3 py-2">
          <option value="">Select Website</option>
          <option v-for="(subtopics, topic) in topics.result" :key="topic" :value="topic">{{ topic }}</option>
        </select>
      </div>

      <!-- Apply button -->
      <button
        @click="applyFilters"
        class="bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 font-bold py-2 px-6 rounded-[3px] mt-auto"
      >
        Apply
      </button>
    </div>

    <!-- Background overlay (optional for UX) -->
    <div
      v-if="isSidebarOpen"
      @click="toggleSidebar"
      class="fixed inset-0 bg-black bg-opacity-50 z-40"
    ></div>
  </div>

  <div class="text-white min-h-screen">
    <div class="flex gap-6">
      <div class="w-[70%]">
        <div class="grid grid-cols-3 gap-6 mb-8">
          <Tile title="Reviews" :value="dashbaordData?.total_reviews" />
          <Tile title="Average Rating" :value="dashbaordData?.average_rating?.toFixed(2)" />
          <Tile title="Average Sentiment" :value="dashbaordData?.average_sentiment.toFixed(2)" />
        </div>
        <div class="grid grid-cols-2 gap-6">
          <div class="secondary-bg rounded-lg p-6">
            <h3 class="mb-4 text-xl font-semibold">Average Sentiment</h3>
            <LineChart :chart-data="dashbaordData?.charts?.average_sentiment_chart" />
          </div>
          <div class="secondary-bg rounded-lg p-6">
            <h3 class="mb-4 text-xl font-semibold">Topic</h3>
            <BarChart :topic-chart-data="dashbaordData?.charts?.topic_chart" :subtopic-chart-data="dashbaordData?.charts?.subtopic_chart" />
          </div>
        </div>
      </div>

      <div class="w-[30%] secondary-bg rounded-lg px-6 py-4">
        <h3 class="mb-4 text-[1.375rem] font-semibold">Reviews</h3>
        <div class="overflow-y-auto review-box">
          <ReviewCard
            v-for="(item, index) in dashbaordData?.reviews"
            :key="index"
            :title="`Review ${index + 1} - ${item['Product Name']}`"
            :content="item['Review Text']"
            :sentiment="item['Sentiment']"
          />
        </div>
      </div>
    </div>
    <div class="w-full flex-1 mt-8">
      <div class="flex justify-end">
        <button
          @click="downloadFile('recommendation')"
          class="bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 font-bold py-2 px-6 rounded-[3px] mb-2"
        >
          Download
        </button>
      </div>
      <DataTable :rows="dashbaordData?.recommendation" :headers="tableHeaders" :perPage="10" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Tile from "@/components/Dashboard/Tile.vue";
import LineChart from "@/components/Dashboard/LineChart.vue";
import BarChart from "@/components/Dashboard/BarChart.vue";
import ReviewCard from "@/components/Dashboard/ReviewCard.vue";
import DataTable from "@/components/DataTable.vue";

export default {
  props: {
  projectId: {
    type: [String, Number], // Adjust type according to your needs
    required: true, // projectId is mandatory
  },
  projectUploadId: {
    type: [String, Number], // Adjust type according to your needs
    required: false, // projectUploadId is optional
    default: null, // Default value when not provided
  },
},
  data() {
    return {
      isSidebarOpen: false,
      dropdowns: [
        { label: "Product Category", options: ["Choice 1", "Choice 2", "Choice 3"], selected: null },
        { label: "Product Name", options: ["Select 1", "Select 2", "Select 3"], selected: null },
        { label: "Website", options: ["Pick 1", "Pick 2", "Pick 3"], selected: null },
      ],
      tableHeaders: {
        name: "Product Name",
        pros: "Pros",
        cons: "Cons",
        designRecommendation: "Design Recommendation",
      },
      selectedTopic: '',
      selectedSubtopic: '',
      selectedWebsite: '',
      selectedProduct: '',
      selectedCategory: '',
      isApplyingFilter: false
    };
  },
  computed: {
    ...mapState("dashboard", ["dashbaordData"]),
    ...mapState("topics", ["topics"]),
    filteredSubtopics() {
      console.log(this.selectedTopic, 'this.selectedTopic ds')
      console.log(this.topics, 'this.topics sds')
      return this.selectedTopic ? this.topics[this.selectedTopic] : [];
    },
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    applyFilters() {
      console.log("Filters applied:", this.dropdowns.map(d => d.selected));
      this.isApplyingFilter = true
      // Delay the toggleSidebar call by 20 seconds
      setTimeout(() => {
        this.toggleSidebar();
        this.isApplyingFilter = false
      }, 20000); // 20000 ms = 20 seconds
    },
    updateSubtopics() {
      // This method can be used to handle any additional logic on topic change if needed
      this.selectedSubtopic = '';  // Reset subtopic when topic changes
    },
    downloadFile(downloadFileFor) {
      let fileUrl;
      if (downloadFileFor === 'dashboard') {
        fileUrl = this.dashbaordData?.dashboard_data_url;
      } else {
        fileUrl = this.dashbaordData?.recommendation_data_url;
      }

      if (fileUrl) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileUrl.split('/').pop(); // Extracts file name from URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('File URL is not available');
      }
    }
  },
  created() {
    console.log(this.dashbaordData, 'dashbaordData dashbaordData')
  },
  components: {
    Tile,
    LineChart,
    BarChart,
    ReviewCard,
    DataTable
  },
};
</script>
<style>
.review-box {
  max-height: 40vh;
  min-height: 30vh;
}
.sidebar{ 
  background: #1E1E1E;
}

.custom-dropdown {
  padding-bottom: 9px;
}

.secondary-bg {
  background-color: #2b2b2b;
}

.filter-subtitle {
  color: #A4A4A4;
}
</style>
